<template>
  <router-link :to="{ name: 'collaborations.show', params: { id: collaboration.id } }" class="rounded-lg border">
    <div class="relative overflow-hidden h-24 sm:h-32 md:h-36 w-full rounded-t-lg flex items-end justify-start" :class="hasPendingAction ? 'opacity-60' : ''">

      <tag :state="collaboration?.restaurant?.state" style="margin-top: 5px;"/>
      <div v-if="collaboration.is_premium" class="absolute text-white top-0 right-0 bg-yellow_button text-onboarding_gray rounded-full" style="margin-top: 5px; margin-right: 5px;">
        <div class="p-1 px-2 flex items-center">
          <a class="pi pi-star mr-2" style="font-size: 12px;"></a>
          <p class="font-bold">{{ $filters.currencyFormatter(collaboration?.foodie_premium_earning_e2 / 100) + '€'}}</p>
        </div>
      </div>

      <div class="absolute from-black opacity-25 bg-gradient-to-t inset-0 flex items-end justify-start" />

<!--      <div v-if="collaboration?.restaurant?.state" class="cityTag text-sm">{{ collaboration.restaurant.state }}</div>-->
      <img
          v-if="collaboration.restaurant.cover_picture"
          :src="collaboration.restaurant.cover_picture"
          :alt="`${collaboration.restaurant.name} profile picture`"
          class="w-full h-full object-cover">
      <img
          v-else
          src="@/assets/restaurant-cover.jpg"
          :alt="`${collaboration.restaurant.name} profile picture`"
          class="w-full h-full object-cover">
      <b class="text-xl text-white text-left leading-tight px-2 py-1 absolute bottom-0 left-0">
        {{ collaboration.restaurant.name }}
      </b>
    </div>

    <div class="flex flex-col p-2 space-y-0 sm:space-y-2 leading-tight text-black select-none">
      <div class="w-full flex justify-between font-medium">
        <span>
          {{ isOneDay ? $filters.moment(collaboration.start_at, 'DD/MM/YY', true) : `Del ${$filters.moment(collaboration.start_at, 'DD/MM/YY', true)}` }}
        </span>
        <span class="h-2 w-2 rounded-full bg-red-600 animate-pulse" v-if="collaboration?.pivot?.foodie_pending_action" />
      </div>
      <div class="w-full flex justify-between">
        <span>
          {{ isOneDay ? $filters.moment(collaboration.end_at, 'HH:mm', true) : `al ${$filters.moment(collaboration.end_at, 'DD/MM/YY', true)}` }}
        </span>
        <span class="font-bold text-lg">
          {{ $filters.collaboration.discount(collaboration.discount_type, collaboration.discount_value) }}
        </span>
      </div>
      <div class="w-full flex justify-between items-center">
        <span class="text-xs text-gray-500 flex-shrink-0 bg-yellow-400 bg-opacity-50 p-0.5 inline-flex" v-if="collaboration.foodies_meeting">
          FOODIE QUEDADA
        </span>
        <div v-else class="font-regular grid grid-cols-7 items-center text-center" style="max-width: 250px; gap: 0.1rem;">
          <div v-for="day in days" :key="day" class="justify-center items-center">
            <div
                class="flex items-center justify-center days-grid"
                :class="{'bg-onboarding_light_gray text-white rounded-full': transformDays.includes(day.id)}">
              <p class="leading-none">
                {{ day.label }}
              </p>
            </div>
          </div>
        </div>
<!--        <span class="text-xs flex-shrink-0 text-gray-500" v-else>-->
<!--          <template v-if="collaboration?.allowed_week_days.length > 6">-->
<!--            Todos los dias-->
<!--          </template>-->
<!--          <template v-else-if="collaboration?.allowed_week_days.length !== 1">-->
<!--            De lunes a jueves-->
<!--          </template>-->
<!--        </span>-->

        <span class="text-xs text-gray-400">
          <span class="space-x-1 font-medium flex items-center">
            <template v-if="collaboration.foodies_meeting">
              <i class="icon-users text-default-2xl" />
              <span>+0</span>
            </template>
            <template v-else>
              <i class="icon-user text-xl" />
              <span>+{{ collaboration?.companions_limit }}</span>
            </template>
          </span>
        </span>
      </div>
    </div>
  </router-link>
</template>

<script>
import { defineComponent, inject, computed, ref } from 'vue'
import Tag from '../../../../../components/Tag'

export default defineComponent({
  props: {
    collaboration: { required: true, type: Object },
    userId: { required: false, type: Number }
  },
  components: {
    Tag
  },
  setup (props) {
    const $filters = inject('$filters', {})

    const isOneDay = computed(() => {
      const start = $filters.moment(props.collaboration.start_at, 'DD/MM/YY')
      const end = $filters.moment(props.collaboration.end_at, 'DD/MM/YY')
      return start === end
    })

    const transformDays = computed(() => {
      return props.collaboration.allowed_week_days.map((day) => (parseInt(day)))
    })

    const days = ref([
      { label: 'L', id: 1 },
      { label: 'M', id: 2 },
      { label: 'M', id: 3 },
      { label: 'J', id: 4 },
      { label: 'V', id: 5 },
      { label: 'S', id: 6 },
      { label: 'D', id: 7 }
    ])

    const shouldShowPendingActivities = computed(() => {
      if (props.collaboration?.pivot?.status === 'accepted' && new Date().toISOString() > props.collaboration?.end_at) {
        return [
          // props.collaboration.pivot?.post_shared,
          props.collaboration?.pivot?.restaurant_rated
        ].some(activity => !activity)
      } else {
        return [
          // props.collaboration.pivot?.post_shared,
          props.collaboration?.pivot?.restaurant_rated
        ].some(activity => activity)
      }
    })

    const hasPendingAction = computed(() => {
      if (props.collaboration.type === 'public') {
        if (props.collaboration.foodies.length > 0) {
          const foodies = props.collaboration.foodies.map($0 => $0.id)
          return foodies.includes(props.userId)
        } else {
          return false
        }
      } else {
        return false
      }
    })

    const handlePictureError = (event) => {
      event.target.src = require('@/assets/restaurant-cover.jpg')
    }

    return {
      isOneDay,
      shouldShowPendingActivities,
      hasPendingAction,
      days,
      transformDays,
      handlePictureError
    }
  }
})
</script>
<style scoped >
/*.cityTag{*/
/*  position: absolute;*/
/*  background-color: #FEFCD7;*/
/*  padding: 4px 15px;*/
/*  border-radius: 5px;*/
/*  top: 0;*/
/*  margin-top: 5px;*/
/*  margin-left: 5px;*/
/*  color: #000;*/
/*}*/
.days-grid {
  width: 20px;
  height: 20px;
  min-width: 20px;
}

@media (max-width: 450px) {
  .days-grid {
    width: 16px;
    height: 16px;
    min-width: 15px;
    font-size: 10px;
  }
}
</style>
