<template>
<!--  <a-dims-search-bar>-->
<!--    <div class="flex flex-col">-->
<!--      <div class="uppercase space-x-2 text-gray">-->
<!--        <h1 class="text-black font-bold text-primary py-4">-->
<!--          Colaboraciones-->
<!--        </h1>-->
<!--      </div>-->
<!--    </div>-->
<!--  </a-dims-search-bar>-->

  <a-dims-search-bar
    placeholder="Buscar colaboración"
    hide-search
    @search="fetch({ search: $event })">

    <router-link
      active-class="border-b-2 border-primary text-primary"
      to="/collaborations"
      class="inline-block py-4 font-bold">
      colaboraciones
    </router-link>
  </a-dims-search-bar>

  <div class="pb-16 pt-20 px-4 space-y-4">
    <my-collaborations v-if="!$route.query.restaurant" />
    <pending-collaborations v-if="!$route.query.restaurant" />
    <discover-collaborations />
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import MyCollaborations from './my-collaborations'
import PendingCollaborations from './pending-collaborations'
import DiscoverCollaborations from './discover-collaborations'
import { useSession } from '../../../../../hooks'

export default defineComponent({
  components: {
    DiscoverCollaborations, // eslint-disable-line
    PendingCollaborations, // eslint-disable-line
    MyCollaborations // eslint-disable-line
  },
  setup () {
    const { user } = useSession()

    onMounted(() => {
      window.scrollTo({ top: 0 })
    })

    return {
      user
    }
  }
})
</script>
