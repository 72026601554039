<template>
  <div class="flex justify-between w-full items-end">
    <h1 class="text-xl font-medium text-black">
      Invitaciones pendientes ({{ pagination.total }})
    </h1>
    <button @click="isOpen = !isOpen" v-if="pagination.total > (isDesktop ? 4 : 2)">
      {{ isOpen ? 'Cerrar' : 'Ver todos' }}
    </button>
  </div>

  <div class="flex flex-col items-start space-y-2" v-if="pagination.total >= 1 || (loading && !pagination.data.length)">

    <div class="grid gap-2 grid-cols-2 md:grid-cols-3 grid-rows-1 xl:grid-cols-4 w-full">
      <template v-if="loading && !pagination.data.length">
        <collaboration-cell-skeleton v-for="n in 2" :key="n" />
      </template>
      <template v-else>
        <collaboration-cell
          v-for="(collaboration, key) in pagination.data"
          :key="key"
          :collaboration="collaboration"
          class="w-full flex-shrink-0" />
      </template>
    </div>

    <div class="flex justify-center w-full pt-4" v-if="shouldShowLoadMoreButton">
      <button @click="fetch({ page: pagination.current_page + 1 })" :disabled="loading" class="px-8 w-full inline-flex sm:w-auto py-2 rounded-full border shadow-md bg-white hover:bg-gray-100 transition-all hover:bg-opacity-50 items-center justify-center">
        <template v-if="!loading">
          Ver mas
        </template>
        <a-loader class="h-5 w-5" v-else />
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, computed } from 'vue'
import CollaborationCell from './collaboration-cell'
import { useRepository, useWindowSize, useSession } from '../../../../../hooks'
import CollaborationCellSkeleton from './collaboration-cell-skeleton'

export default defineComponent({
  components: {
    CollaborationCellSkeleton,
    CollaborationCell
  },
  setup () {
    const isOpen = ref(false)
    const { isAuthenticated } = useSession()
    const query = reactive({ limit: 10, with: 'foodies,restaurant', type: 'private', status: 'pending' })
    const loading = ref(false)
    const results = ref({ data: [], total: 0, next_page_url: null, current_page: 1 })
    const repository = useRepository()
    const { isDesktop } = useWindowSize()

    const fetch = (search = {}) => {
      const queryString = { ...query, ...search }
      loading.value = !loading.value
      repository.collaborations
        .available(queryString)
        .then(({ data }) => {
          results.value = {
            ...results.value,
            ...data,
            data: results.value.data.concat(data.data)
          }
        })
        .finally(() => {
          loading.value = !loading.value
        })
    }

    const pagination = computed(() => ({
      ...results.value,
      data: isOpen.value ? results.value.data : results.value.data.slice(0, isDesktop.value ? 4 : 2)
    }))

    const shouldShowLoadMoreButton = computed(() => {
      return isOpen.value && !!pagination.value.next_page_url
    })

    onMounted(() => {
      if (isAuthenticated) {
        fetch()
      }
    })

    return {
      shouldShowLoadMoreButton,
      fetch,
      pagination,
      isOpen,
      loading,
      isDesktop
    }
  }
})
</script>
