<template>
  <a-dims-search-bar
    :placeholder="searchAction.placeholder"
    hide-search
    @search="searchAction.action({
      segment: { search: $event },
      appending: false,
    })">
    <router-link
      active-class="border-b-2 border-primary text-primary"
      to="/collaboration"
      class="inline-block py-4 font-bold">
      Colaboraciones
    </router-link>
  </a-dims-search-bar>

<!--  <div class="pb-16 pt-16">-->
<!--    <div class="flex justify-between text-gray-400 w-full py-1 px-4">-->
<!--      <div class="space-x-2">-->
<!--        <router-link @click="fetchOpen" exact-active-class="font-bold text-black" :to="{ name: 'collaborations.restaurant.public' }">-->
<!--          ABIERTAS ({{ pagination.open.total ?? 0 }})-->
<!--        </router-link>-->
<!--        <router-link @click="fetchPrivate" active-class="font-bold text-black" :to="{ name: 'collaborations.restaurant.private' }">-->
<!--          PRIVADAS ({{ pagination.private.total ?? 0 }})-->
<!--        </router-link>-->

<!--      </div>-->
<!--      <div id="sort-section" />-->
<!--    </div>-->
<!--    <router-view-->
<!--      :open-pagination="pagination.open"-->
<!--      :open-loading="loadings.open"-->
<!--      @fetch-open="fetchOpen"-->
<!--      :private-pagination="pagination.private"-->
<!--      :private-loading="loadings.private"-->
<!--      @fetch-private="fetchPrivate"/>-->
<!--  </div>-->
  <h2 class="px-normal text-lg font-bold text-left text-black" style="margin-top: 70px">
    Mis Colaboraciones ({{ pagination.collaborations.total}})
  </h2>
<!--  <div v-if="loadings.collaborations" class="custom-loading">-->
<!--    <a-loader color="gray_color h-1 w-1"/>-->
<!--  </div>-->
  <div>
<!--    @bottom-reached="fetchCollaborations({ segment: { page: pagination.collaborations.next_page_url ? pagination.collaborations.current_page + 1 : pagination.collaborations.current_page },-->
    <div v-if="pagination?.collaborations?.total <= 0 && !loadings.collaborations" class="border rounded-md m-normal p-small">
      <div class="w-6/12 text-center mx-auto block py-small">
        <h3>
          No has creado ninguna colaboración todavía
        </h3>
        <h3 @click="publicCollaboration" class="text-primary pt-small cursor-pointer">+ Crear colaboración</h3>
      </div>
    </div>
    <a-list
      v-else
      class="pb-16 public-collab"
      :source="pagination.collaborations.data"
      :loading="loadings.collaborations"
      :should-emit-bottom-reached="pagination.collaborations.next_page_url !== null"
      @bottom-reached="fetchCollaborations({ page: pagination.collaborations.next_page_url ? pagination.collaborations.current_page + 1 : pagination.collaborations.current_page }, true)"
      #default="{ item }">
      <div class="px-normal">

        <div @click="showCollaboration(item.id)" class="pb-0 pt-sm" >
          <div class="flex text-left py-small border rounded-md p-normal items-center cursor-pointer">
            <div class="flex-none pr-sm">
              <div v-if="item.rest_pending_action" class="h-2 w-2 bg-pointred rounded rounded-full"></div>
              <div :class="`donut ${item.uses > (item.foodies.length * 0.8 )? `completed_donut` : ``}`" :style="`--first:${1-(item.uses/(item.foodies.length > 0 ? item.foodies.length : 1))}; --second: ${item.uses/(item.foodies.length > 0 ? item.foodies.length : 1)}`">
                <div class="donut__slice donut__slice__first"></div>
                <div :class="`donut__slice ${ item.uses > (item.foodies.length * 0.8)? `donut__slice__completed` : `donut__slice__second`}`"></div>
                <div class="donut__label">
                  <i v-if="item.foodies_meeting" class="icon-users"></i>
                  <i v-else class="icon-user"></i>
                  <p v-if="item.type === 'public'">{{ `${item.uses}/${item.uses_limit}` }}</p>
                  <p v-else>{{ `${item.uses}/${item.foodies.length}` }}</p>
                </div>
              </div>
            </div>
            <div class="flex-auto px-small">
              <div class="text-left">
                <div v-if="item.foodies_meeting" class="inline_label"><div class="label_quedada l_q_yellow">Foodie quedada</div></div>
                <div v-if="item.type !== 'private'" class="inline_label"><div class="label_quedada l_q_green">pública</div></div>
<!--                <div>-->
<!--                  <div v-if="item.start_at === item.end_at || item.foodies_meeting">-->
<!--                    <div class="capitalize">-->
<!--                      {{ formatDay(item.start_at) + ' ' + formatDate(item.start_at) }}-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      {{ formatHour(item.start_at) }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div v-else class="text-black">{{ 'Del ' + formatDate(item.start_at) + ' al ' + formatDate(item.end_at)}}</div>-->
<!--                  <div v-else class="text-black">{{ 'Del ' + formatDate(item.start_at) + ' al ' + formatDate(item.end_at)}}</div>-->
<!--                </div>-->
                <div class="font-regular" v-html="getDate(item)"></div>

<!--                <p v-if="item.allowed_week_days.length > 6" class="font-regular" style="line-height: 1.1;">Todos los días</p>-->
<!--                <p v-else-if="item.allowed_week_days.length !== 1" class="font-regular" style="line-height: 1.1;">Lunes a jueves</p>-->
                <div class="font-regular grid grid-cols-7 items-center text-center" style="max-width: 150px; gap: 1rem;">
                  <div v-for="day in days" :key="day" class="justify-center items-center">
                    <div
                        class="flex items-center justify-center days-grid"
                        style="width: 20px; height: 20px;"
                        :class="{'bg-onboarding_light_gray text-white rounded-full': transformDays(item).includes(day.id)}">
                      <p class="leading-none">
                        {{ day.label }}
                      </p>
                    </div>
                  </div>
                </div>
                <p v-if="!item.foodies_meeting" class="font-regular">
                  <i class="icon-user"></i>
                  +{{ item.companions_limit }} acompañantes
                </p>
                <div class="">
                  <p
                      v-for="foodie
                      in item.foodies"
                      :key="foodie"
                      class="font-regular text-ellipsis inline-block mr-2 text-primary font-bold custom-text-width"
                      style="line-height: 1.1; overflow: hidden; text-overflow: ellipsis;">
                    @{{ foodie.ig_username }}
                  </p>
                </div>
                <!--p class="font-light">Fecha de creación:</p>
                <p class="font-light tt_gray">{{ getCreationDate }}</p-->
              </div>
            </div>
            <div class="flex-none self-start">
              <div class="inline_discount">
                <div v-if="item.is_premium" class="flex items-center ml-auto justify-center bg-yellow_ribbon rounded-full h-6 w-6">
                  <i class="pi pi-star" style="font-size: 12px"></i>
                </div>
                <b v-if="item.discount_type === 'prc'" class="text-xl text-black">{{ `${item.discount_value}%` }}</b>
                <b v-if="item.discount_type === 'fixed_e2'" class="text-xl text-black">{{ item.discount_value / 100 + '€' }}</b>
              </div>
            </div>
          </div>
          <!--    <div v-if="user.isRestaurant" class="flex text-left">-->
        </div>
      </div>
    </a-list>
  </div>
  <div class="block cbc">
    <button @click="publicCollaboration" class="button bg-primary text-white h-base">Crear Colaboración</button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import moment from 'moment'

export default {
  props: ['values'],
  data: () => ({
    completed: true,
    pagination: {
      open: { data: [] },
      private: { data: [] },
      confirmed: { data: [] },
      collaborations: { data: [] }
    },
    loadings: {
      open: false,
      private: false,
      confirmed: false,
      collaborations: false
    },
    days: [
      { label: 'L', id: 1 },
      { label: 'M', id: 2 },
      { label: 'M', id: 3 },
      { label: 'J', id: 4 },
      { label: 'V', id: 5 },
      { label: 'S', id: 6 },
      { label: 'D', id: 7 }
    ],
    query: {
      collaborations: {
        limit: 15,
        with: 'foodies',
        status: 'active',
        page: 1,
        sort_by: 'created_at,desc'
        // mode: 'actives'
      },
      open: {
        limit: 15,
        type: 'public',
        with: 'foodies',
        status: 'active',
        sort_by: 'created_at,desc'
      },
      private: {
        limit: 15,
        type: 'private',
        with: 'foodies',
        status: 'active',
        sort_by: 'created_at,desc'
      },
      confirmed: {
        limit: 15,
        type: 'public',
        with: 'restaurant',
        sort_by: 'created_at,desc'
      }
    }
  }),
  computed: {
    ...mapState({
      user: ({ session }) => session.user
    }),
    searchAction () {
      const view = this.$route.path.split('/').slice(-1)[0]
      switch (view) {
        case 'private': return {
          placeholder: 'Buscar privadas',
          action: this.fetchPrivate
        }
        case 'confirmed': return {
          placeholder: 'Buscar confirmadas',
          action: this.fetchConfirmed
        }
        default: return {
          placeholder: 'Buscar abiertas...',
          action: this.fetchOpen
        }
      }
    }
  },
  methods: {
    // getDate () {
    //   if (this.values.start_at !== this.values.end_at) {
    //     return `<b style="text-transform: capitalize;">
    //     ${moment(this.values.end_at).format('dddd DD/MM/YY')} </br>
    //     ${moment(this.values.end_at).format('HH:mm')}
    //     </b>`
    //   } else {
    //     return `Del ${moment(this.values.start_at).format('DD/MM/YY')} al ${moment(this.values.end_at).format('DD/MM/YY')}`
    //   }
    // },
    transformDays (item) {
      return item.allowed_week_days.map((day) => (parseInt(day)))
    },
    dateFormatter (startAt, endAt) {
      const startAtValue = new Date(startAt)
      const endAtValue = new Date(endAt)
      const formatTime = (time) => {
        if (time < 10) {
          return `0${time}`
        }
        return time
      }
      const dateItems = {
        start_at: {
          DD: '',
          MM: '',
          YY: '',
          hh: '',
          mm: ''
        },
        end_at: {
          DD: '',
          MM: '',
          YY: '',
          hh: '',
          mm: ''
        }
      }
      const dateFormatted = {
        start_at: {},
        end_at: {}
      }
      dateItems.start_at.DD = startAtValue.getUTCDate()
      dateItems.start_at.MM = startAtValue.getUTCMonth()
      dateItems.start_at.YY = startAtValue.getUTCFullYear()
      dateItems.start_at.hh = startAtValue.getUTCHours()
      dateItems.start_at.mm = startAtValue.getUTCMinutes()

      dateItems.end_at.DD = endAtValue.getUTCDate()
      dateItems.end_at.MM = endAtValue.getUTCMonth()
      dateItems.end_at.YY = endAtValue.getUTCFullYear()
      dateItems.end_at.hh = endAtValue.getUTCHours()
      dateItems.end_at.mm = endAtValue.getUTCMinutes()

      dateFormatted.start_at.date = dateItems.start_at.DD + '/' + parseInt(dateItems.start_at.MM + 1) + '/' + dateItems.start_at.YY
      dateFormatted.start_at.time = formatTime(dateItems.start_at.hh) + ':' + formatTime(dateItems.start_at.mm)

      dateFormatted.end_at.date = dateItems.end_at.DD + '/' + parseInt(dateItems.end_at.MM + 1) + '/' + dateItems.end_at.YY
      dateFormatted.end_at.time = formatTime(dateItems.end_at.hh) + ':' + formatTime(dateItems.end_at.mm)

      return dateFormatted
    },
    getDate (item) {
      if (item.start_at !== item.end_at) {
        // console.log(`Del ${moment(item.start_at).format('DD')} al ${moment(item.end_at).format('DD')}`)
        return `Del ${this.$filters.moment(item.start_at, 'DD/MM/YYYY', true)} al ${this.$filters.moment(item.end_at, 'DD/MM/YYYY', true)}`
      } else {
        if (!item.foodies_meeting) {
          return `Del ${this.$filters.moment(item.start_at, 'DD/MM/YYYY', true)} al ${this.$filters.moment(item.end_at, 'DD/MM/YYYY', true)}`
        } else {
          return `<p style="text-transform: capitalize;">
        ${this.$filters.moment(item.start_at, 'dddd DD/MM/YYYY', true)}</br>
        ${this.$filters.moment(item.start_at, 'HH:mm', true)}h
        </p>`
        }
      }
    },
    formatDate (date) {
      return this.$filters.moment(date, 'DD/MM/YY')
    },
    formatDay (date) {
      return this.$filters.moment(date, 'dddd')
    },
    formatHour (date) {
      return this.$filters.moment(date, 'HH:mm')
    },
    queryContains (section) {
      return this.$route.query.section === section
    },
    isCurrentRoute (name) {
      return this.$router.currentRoute.value.name === name
    },
    fetchConfirmed ({ segment = {}, appending = false, toggle = () => {}, handler = () => {} }) {
      const query = { ...this.query.confirmed, ...segment }
      this.loadings.confirmed = !this.loadings.confirmed
      this.$repository.restaurants
        .collaborations(query, { cancelable: false })
        .then(({ data: pagination }) => {
          this.pagination.confirmed = appending
            ? { ...pagination, data: [...this.pagination.confirmed.data, ...pagination.data] }
            : pagination

          this.query.confirmed = query
          toggle()
          handler()
        })
        .finally(() => {
          this.loadings.confirmed = !this.loadings.confirmed
        })
    },
    // fetchCollaborations2 ({ segment = {}, appending = false, toogle = () => {}, handler = () => {} }) {
    //   const query = { ...this.query.collaborations, ...segment }
    //   this.loadings.collaborations = !this.loadings.collaborations
    //   this.$repository.collaborations
    //     .index(query, { cancelable: false })
    //     .then(({ data: pagination }) => {
    //       this.pagination.collaborations = appending
    //         ? { ...pagination, data: [...this.pagination.collaborations.data, ...pagination.data] }
    //         : pagination
    //
    //       this.query.collaborations = query
    //       // console.log(this.pagination.collaborations)
    //       toogle()
    //       handler()
    //     })
    //     .finally(() => {
    //       this.loadings.collaborations = !this.loadings.collaborations
    //     })
    // },
    fetchCollaborations (segment = {}, appending = false, toogle = () => {}, handler = () => {}) {
      const query = { ...this.query.collaborations, ...segment }
      this.loadings.collaborations = !this.loadings.collaborations
      this.$repository.collaborations
        .index(query, { cancelable: false })
        .then(({ data: pagination }) => {
          this.pagination.collaborations = appending
            ? { ...pagination, data: [...this.pagination.collaborations.data, ...pagination.data] }
            : pagination

          this.query.collaborations = query
          // console.log(this.pagination.collaborations)
          toogle()
          handler()
        })
        .finally(() => {
          this.loadings.collaborations = !this.loadings.collaborations
        })
    },
    fetchPrivate ({ segment = {}, appending = false, toggle = () => {}, handler = () => {} }) {
      const query = { ...this.query.private, ...segment }
      this.loadings.private = !this.loadings.private
      this.$repository.collaborations
        .index(query, { cancelable: false })
        .then(({ data: pagination }) => {
          this.pagination.private = appending
            ? { ...pagination, data: [...this.pagination.private.data, ...pagination.data] }
            : pagination

          this.query.private = query
          toggle()
          handler()
        })
        .finally(() => {
          this.loadings.private = !this.loadings.private
        })
    },
    fetchOpen ({ segment = {}, appending = false, toggle = () => {}, handler = () => {} }) {
      const query = { ...this.query.open, ...segment }
      this.loadings.open = !this.loadings.open
      this.$repository.collaborations
        .index(query, { cancelable: false })
        .then(({ data: pagination }) => {
          this.pagination.open = appending
            ? { ...pagination, data: [...this.pagination.open.data, ...pagination.data] }
            : pagination

          this.query.open = query

          toggle()
          handler()
        })
        .catch(error => (console.log(error)))
        .finally(() => (this.loadings.open = !this.loadings.open))
    },
    showCollaboration (id) {
      this.$router.push({ path: `/collaborations/${id}` })
    },
    publicCollaboration () {
      this.$router.replace({ name: 'collaboration.create.public' })
      // console.log('on construction')
    }
  },
  mounted () {
    // this.fetchOpen({})
    // this.fetchPrivate({})
    this.fetchCollaborations({})
    window.scrollTo({ top: 0 })
    // this.fetchConfirmed({})
  }
}
</script>

<style scoped>
#collabCircle{
  width: 72px;
  height: 72px;
  display: block;
  margin: 0 auto;
}
.inline_label{
  line-height: 0;
}

@media (max-width: 400px) {
  .custom-text-width {
    max-width: 12rem;
  }
}

@media (max-width: 800px) {
  .custom-text-width {
    max-width: 40vw;
  }
}
</style>
