<template>
  <div>
    <component :is="`collaborations-${user.isRestaurant ? 'restaurant' : 'foodie'}`"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import collaborationsFoodie from '@/views/authenticated/foodies/collaboration/index/Index'
import collaborationsRestaurant from '@/views/authenticated/restaurants/collaboration/index/Index'
// import axios from 'axios'

export default {
  name: 'Collaboration',
  // data () {
  //   return {
  //     values: []
  //   }
  // },
  components: {
    collaborationsFoodie,
    collaborationsRestaurant
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    })
  },
  methods: {
    ...mapActions({
      collaborations: 'collaborations/index'
    })
  }
}
</script>

<style scoped>

</style>
