<template>
  <div class="flex justify-between w-full items-end">
    <h1 class="text-xl font-medium text-black">
      Descubre colaboraciones ({{ pagination?.total }})
    </h1>
<!--    <button @click="isOpen = !isOpen" v-if="pagination.total > (isDesktop ? 4 : 2)">-->
<!--      {{ isOpen ? 'Cerrar' : 'Ver todos' }}-->
<!--    </button>-->
  </div>
  <a-list
    variation
    :source="pagination.data"
    :loading="loading"
    :should-emit-bottom-reached="results.next_page_url !== null"
    @bottom-reached="fetch({ page: results.next_page_url ? results.current_page + 1 : results.current_page })"
  >
<!--    <template class="grid gap-2 grid-cols-2 md:grid-cols-3 grid-rows-1 xl:grid-cols-4 w-full">-->
    <template #default="{ item }" >
      <collaboration-cell
        :collaboration="item"
        :userId="user.foodie.id"
        class="w-full flex-shrink-0" />
    </template>
<!--    </template>-->
  </a-list>
<!--  <div class="flex flex-col items-start space-y-2 w-full" v-if="pagination.total >= 1 || (loading && !pagination.data.length)">-->

<!--    <div class="grid gap-2 grid-cols-2 md:grid-cols-3 grid-rows-1 xl:grid-cols-4 w-full">-->
<!--      <template v-if="loading && !pagination.data.length">-->
<!--        <collaboration-cell-skeleton v-for="n in 2" :key="n" />-->
<!--      </template>-->
<!--      <template v-else>-->
<!--        <collaboration-cell-->
<!--          v-for="(collaboration, key) in pagination.data"-->
<!--          :key="key"-->
<!--          :collaboration="collaboration"-->
<!--          :userId="user.foodie.id"-->
<!--          class="w-full flex-shrink-0" />-->
<!--      </template>-->
<!--    </div>-->

<!--    <div class="flex justify-center w-full pt-4" v-if="shouldShowLoadMoreButton">-->
<!--      <button @click="fetch({ page: pagination.current_page + 1 })" :disabled="loading" class="px-8 w-full inline-flex sm:w-auto py-2 rounded-full border shadow-md bg-white hover:bg-gray-100 transition-all hover:bg-opacity-50 items-center justify-center">-->
<!--        <template v-if="!loading">-->
<!--          Ver mas-->
<!--        </template>-->
<!--        <a-loader class="h-5 w-5" v-else />-->
<!--      </button>-->
<!--    </div>-->
<!--  </div>-->
  <div class="border rounded-xl flex-col space-y-4 border-gray-150 p-4 flex items-center justify-center" v-if="$route.query.restaurant && !loading && !pagination.data.length">
    <p class="text-black font-medium text-default-lg">
      En este momento no hay colaboraciones de este restaurante
    </p>

    <button @click="$router.replace({ query: {} })" class="text-primary">
      Ver todas las colaboraciones
    </button>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, computed, watch } from 'vue'
import CollaborationCell from './collaboration-cell'
import { useRepository, useWindowSize, useSession } from '../../../../../hooks'
// import CollaborationCellSkeleton from './collaboration-cell-skeleton'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    // CollaborationCellSkeleton,
    CollaborationCell
  },
  setup () {
    const isOpen = ref(false)
    const { user, isAuthenticated } = useSession()
    const query = reactive({ limit: 15, with: 'foodies,restaurant', type: 'public' })
    const loading = ref(false)
    const results = ref({ data: [], total: 0, next_page_url: null, current_page: 1 })
    const repository = useRepository()
    const { isDesktop } = useWindowSize()
    const route = useRoute()

    const fetch = (segment = {}) => {
      const queryString = { ...query, ...segment }
      loading.value = !loading.value
      repository.collaborations
        .available({ ...queryString }, { cancelable: false })
        .then(({ data }) => {
          results.value = {
            ...results.value,
            ...data,
            data: results.value.data.concat(data.data)
          }
        })
        .finally(() => {
          loading.value = !loading.value
        })

      // this.loading = !this.loading
      // const query = { ...this.query, ...segment }
      // this.$repository.restaurants
      //   .getFavorites(this.user.restaurant.id, query)
      //   .then(({ data: pagination }) => {
      //     this.pagination = appending
      //       ? { ...pagination, data: [...this.pagination.data, ...pagination.data] }
      //       : pagination
      //     this.query = query
      //   })
      //   .finally(() => (this.loading = !this.loading))
    }

    const pagination = computed(() => ({
      ...results.value
      // data: isOpen.value ? results.value.data : results.value.data.slice(0, isDesktop.value ? 4 : 2)
    }))

    const shouldShowLoadMoreButton = computed(() => {
      return isOpen.value && !!pagination.value.next_page_url
    })

    onMounted(() => {
      // fetch({ ...route.query })
      if (isAuthenticated) {
        fetch({ ...route.query })
      }
      // console.log(user.foodie.ig_followers_count)
    })

    watch(() => route.query, (params) => {
      if (isAuthenticated) {
        fetch({ ...route.query })
      }
    })

    return {
      fetch,
      shouldShowLoadMoreButton,
      isOpen,
      pagination,
      loading,
      isDesktop,
      results,
      user
    }
  }
})
</script>
<style scoped>

</style>
