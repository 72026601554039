<template>
  <div class="rounded-lg animate-pulse flex flex-col border space-y-2">
    <div class="overflow-hidden p-2 h-24 sm:h-32 md:h-36 w-full rounded-t-lg flex items-end justify-start bg-gray-100">
      <div class="bg-gray-300 rounded-full h-4 w-24 z-10" />
    </div>
    <div class="grid grid-cols-2 gap-2 px-2 pt-1 pb-2">
      <div class="bg-gray-100 w-full h-4 rounded-full" />
      <div class="bg-gray-100 w-full h-4 rounded-full" />
    </div>
  </div>
</template>
