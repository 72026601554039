<template>
<!--  <div class="cityTagContainer">-->
    <div v-if="state" class="cityTag text-sm capitalize" :style="getColor()" :class="classes">{{ state }}</div>
    <div v-else class="cityTag text-sm" ></div>
<!--  </div>-->
</template>

<script>
export default {
  name: 'Tag',
  props: {
    state: { required: false, type: String },
    classes: { required: false, type: String }
  },
  methods: {
    getColor () {
      switch (this.state.toLowerCase()) {
        case 'madrid': { return 'background: #FEFCD7;' }
        case 'barcelona': { return 'background: #99CC99;' }
        case 'sevilla': { return 'background: #EDDDCB;' }
        case 'bilbao': { return 'background: #C7AFBD;' }
        case 'valencia': { return 'background: #C1DAD6;' }
        case 'málaga': { return 'background: #FFAAA6;' }
      }
    }
  }
}
</script>

<style scoped>
.cityTag{
  /*background-color: #FEFCD7;*/
  position: absolute;
  padding: 4px 15px;
  border-radius: 5px;
  top: 0;
  /*margin-top: 5px;*/
  margin-left: 5px;
  color: #666;
}
.cityTagContainer {
  position: absolute;
}
</style>
